import React from "react";
import Logo from '../images/logo.jpg';
import Orcade from '../images/orcade.png'
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdOutlineLocationOn, MdOutlineEmail, MdOutlineAccessTime } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { FaFacebookSquare, FaInstagramSquare, FaTwitterSquare, FaLinkedin } from "react-icons/fa";
import { FaRegCopyright } from "react-icons/fa6";
import { IoIosArrowDropup } from "react-icons/io";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="footer h-auto border-top py-3" style={{ backgroundColor: "black" }}>
      <footer>
        <div className="d-flex flex-column align-items-center justify-content-center" style={{ color: "#B57EDC" }}>
          <Link to="/">
            <img
              src={Logo}
              alt="logo"
              className="rounded m-2"
              style={{ width: "50px", borderRadius: "50%" }}
            />
          </Link>
          <Link to="/" style={{ textDecoration: "none" }}>
            <h4 className="fw-bold fs-5" style={{ color: "Yellow" }}>
              Attain Smart
            </h4>
          </Link>
          <p style={{ color: "Yellow" }}>A Smart Learn to Wisdom</p>
          <div className="ps-0">
            <div className="d-flex justify-content-center gap-3 m-auto">
              <a href="/" className="fs-3 pb-2" style={{ color: "#11FFED" }}>
                <FaFacebookSquare />
              </a>
              <a href="/" className="fs-3 pb-2" style={{ color: "#11FFED" }}>
                <FaInstagramSquare />
              </a>
              <a href="/" className="fs-3 pb-2" style={{ color: "#11FFED" }}>
                <FaTwitterSquare />
              </a>
              <a href="/" className="fs-3" style={{ color: "#11FFED" }}>
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div>
        <Container fluid>
          <Row className="border-top py-3 row-cols-1 row-cols-sm-2 row-cols-lg-4 ps-0 ps-sm-5">
            <Col>
              <div className="d-flex flex-column contact-info">
                <h5 style={{ color: "Yellow" }} className="py-2 pt-3">
                  Contact Info
                </h5>
                <div className="location text-white d-flex">
                  <MdOutlineLocationOn className="me-2 mt-1" />
                  <p className="fw-light">Guntur Urban, Andhra Pradesh, India</p>
                </div>
                <div className="contact text-white d-flex">
                  <IoCallOutline className="me-2 mt-1" />
                  <p className="fw-light">+91 7893084969 (STEVEN)</p>
                </div>
                <div className="mail text-white d-flex">
                  <MdOutlineEmail className="me-2 mt-1" />
                  <p className="fw-light">attainsmart@gmail.com</p>
                </div>
                <div className="timings text-white d-flex">
                  <MdOutlineAccessTime className="me-2 mt-1" />
                  <p className="fw-light">Opening Hours: 9am - 6pm</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className="d-flex flex-column nav-links">
                <h5 style={{ color: "Yellow" }} className="py-2 pt-3">
                  Services
                </h5>
                <Link
                  to="/"
                  className="text-white pb-3 fw-light"
                  style={{ textDecoration: "none" }}
                >
                  Web & Mobile App Development
                </Link>
                <Link
                  to="/"
                  className="text-white pb-3 fw-light"
                  style={{ textDecoration: "none" }}
                >
                  Academic Workshops
                </Link>
                <Link
                  to="/"
                  className="text-white pb-3 fw-light"
                  style={{ textDecoration: "none" }}
                >
                  E-Learning
                </Link>
                <Link
                  to="/"
                  className="text-white pb-3 fw-light"
                  style={{ textDecoration: "none" }}
                >
                  Freelance
                </Link>
              </div>
            </Col>
            <Col>
              <div className="d-flex flex-column nav-links">
                <h5 style={{  color: "Yellow" }} className="py-2 pt-3">
                  Company
                </h5>
                <Link
                  to="/"
                  className="text-white pb-3 fw-light"
                  style={{ textDecoration: "none" }}
                >
                  Home
                </Link>
                <Link
                  to="/"
                  className="text-white pb-3 fw-light"
                  style={{ textDecoration: "none" }}
                >
                  Careers
                </Link>
                <Link
                  to="/"
                  className="text-white pb-3 fw-light"
                  style={{ textDecoration: "none" }}
                >
                  About
                </Link>
                <Link
                  to="/"
                  className="text-white pb-3 fw-light"
                  style={{ textDecoration: "none" }}
                >
                  Events
                </Link>
                <Link
                  to="/"
                  className="text-white pb-3 fw-light"
                  style={{ textDecoration: "none" }}
                >
                  Contact
                </Link>
              </div>
            </Col>
            <Col>
              <div className="d-flex flex-column nav-links">
                <h5 style={{  color: "Yellow" }} className="py-2 pt-3">
                  Resources
                </h5>
                <Link
                  to="/"
                  className="text-white pb-3 fw-light"
                  style={{ textDecoration: "none" }}
                >
                  Blog
                </Link>
                <Link
                  to="/"
                  className="text-white pb-3 fw-light"
                  style={{ textDecoration: "none" }}
                >
                  eBooks
                </Link>
                <Link
                  to="/"
                  className="text-white pb-3 fw-light"
                  style={{ textDecoration: "none" }}
                >
                  Knowledge Base
                </Link>
                <Link
                  to="/"
                  className="text-white pb-3 fw-light"
                  style={{ textDecoration: "none" }}
                >
                  Testimonials
                </Link>
                <Link
                  to="/"
                  className="text-white pb-3 fw-light"
                  style={{ textDecoration: "none" }}
                >
                  Affiliate Program
                </Link>
                <Link
                  to="/"
                  className="text-white fw-light"
                  style={{ textDecoration: "none" }}
                >
                  Strategic Partners
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="text-center border-top text-white pt-3">
            <Col>
              <div className="d-flex justify-content-around align-items-center">
                <div className="copyright">
                  <FaRegCopyright /> 2024 AttainSmart
                  <p>Terms of Use • Privacy Policy</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className="d-flex justify-content-end align-items-center">
               <p className="me-5 mt-4" style={{fontSize:'20px'}}>Developed By  <a href="https://www.orcadehub.com/" target="_blank" rel="noopener noreferrer" style={{color: "#B57EDC"}}>
                  <img src={Orcade} alt="orcade" height={50}/>OrcadeHub
                </a></p>
              </div>
            </Col>
          </Row>
          <IoIosArrowDropup className="text-white fs-3" onClick={scrollToTop} />
        </Container>
      </footer>
    </div>
  );
};

export default Footer;

import React, { useState } from "react";
import "./Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Logo from '../images/logo.jpg'
import Navbar from "react-bootstrap/Navbar";
import { FaYoutube, FaFacebook, FaInstagram, FaGift } from "react-icons/fa";
import { FaXTwitter, FaBars } from "react-icons/fa6";
import Offcanvas from "react-bootstrap/Offcanvas";
import { RiArrowDropDownLine } from "react-icons/ri";

const Header = () => {
  const [activeLink, setActiveLink] = useState("#action1");
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);

  const handleToggle2 = () => {
    setIsExpanded2(!isExpanded2);
  };
  const handleToggle3 = () => {
    setIsExpanded3(!isExpanded3);
  };

  const handleSelect = (eventKey) => {
    setActiveLink(eventKey);
  };

  return (
    <>
      <div className="header d-flex flex-column" style={{width:'100vw'}}>
        <Navbar expand="lg" className="border-bottom">
          <Container fluid>
            <div className="header_logo d-flex">
              <Nav.Link href="#">
                <img src={Logo} alt="logo" className="me-1" />
              </Nav.Link>
              <Nav.Item>
                <Navbar.Brand
                  href="#"
                  className="nav-brand fs-1"
                  style={{ color: "orange" }}
                >
                  ATTAIN SMART
                </Navbar.Brand>
              </Nav.Item>
            </div>
            <div className="nav-links first-nav d-flex gap-5">
              <Nav.Link href="#" className="call_mail">
                +91 7893084969 , +91 7093012101
              </Nav.Link>
              <Nav.Link href="#" className="call_mail">
                attainsmart@gmail.com
              </Nav.Link>
              <div className="social_links d-flex gap-2">
                <Nav.Link href="#facebook">
                  <FaFacebook className="social_icons" />
                </Nav.Link>
                <Nav.Link href="#twitter">
                  <FaXTwitter className="social_icons" />
                </Nav.Link>
                <Nav.Link href="#instagram">
                  <FaInstagram className="social_icons" />
                </Nav.Link>
                <Nav.Link href="#youtube">
                  <FaYoutube className="social_icons" />
                </Nav.Link>
              </div>
            </div>
          </Container>
        </Navbar>
        <div className="sec-navbar">
          <Navbar className="mb-3" expand="lg">
            <Container fluid>
              <Navbar.Toggle
                aria-controls="offcanvasNavbar-expand-lg"
                style={{ color: "orange", border: "none" }}
                className="toggle"
              >
                <FaBars />
              </Navbar.Toggle>
              <Navbar.Offcanvas
                id="offcanvasNavbar-expand-lg"
                aria-labelledby={"offcanvasNavbarLabel-expand-lg"}
                placement="top"
                className="offcanvas-nav"
              >
                <Offcanvas.Header
                  closeButton
                  className="header_logo_offcanvas custom-close-button "
                >
                  <div className="header_logo d-flex">
                    <Nav.Link href="#">
                      <img
                        src={Logo}
                        alt="logo"
                        style={{ width: "30px" }}
                        className="me-1"
                      />
                    </Nav.Link>
                    <Nav.Item>
                      <Navbar.Brand
                        href="#"
                        className="fw-bold fs-5"
                        style={{ color: "orange" }}
                      >
                        ATTAIN SMART
                      </Navbar.Brand>
                    </Nav.Item>
                  </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav
                    className="justify-content-start flex-grow-1 gap-3 py-2 second_nav"
                    activeKey={activeLink}
                    onSelect={handleSelect}
                    style={{ fontWeight: "600" }}
                  >
                    <Nav.Link
                      href="#home"
                      className="nav-link"
                      eventKey="#home"
                    >
                      Home
                    </Nav.Link>
                    <Nav.Link
                      href="#aboutus"
                      className="nav-link"
                      eventKey="#aboutus"
                    >
                      About Us
                    </Nav.Link>
                    <Nav.Link
                      href="#admissions"
                      className="nav-link"
                      eventKey="#admissions"
                    >
                      Admissions
                    </Nav.Link>
                    {/* <Nav.Link
                      href="#testi"
                      className="nav-link"
                      eventKey="#online-feedback"
                    >
                      Online Feedback
                    </Nav.Link> */}
                    <Nav.Link
                      href="#gallery"
                      className="nav-link"
                      eventKey="#gallery"
                    >
                      Gallery
                    </Nav.Link>
                    <Nav.Link
                      href="#testi"
                      className="nav-link"
                      eventKey="#testimonials"
                    >
                      Testimonials
                    </Nav.Link>
                    <Nav.Link
                      href="#collaborations"
                      className="nav-link"
                      eventKey="#collaborations"
                    >
                      Collaborations
                    </Nav.Link>
                    <Nav.Link
                      href="#contact"
                      className="nav-link"
                      eventKey="#contactus"
                    >
                      Contact Us
                    </Nav.Link>
                    <Nav.Item className="dropdownmenu">
                      <Nav.Link
                        href="#courses"
                        eventKey="#programs-offered"
                        data-bs-toggle="collapse"
                        data-bs-target="#submenu2"
                        aria-expanded={isExpanded2}
                        aria-controls="submenu2"
                        className={` nav-link has-dropdown ${
                          isExpanded2 ? "expanded" : "collapsed"
                        }`}
                      >
                        Programs Offered
                        <RiArrowDropDownLine
                          className="drop-icon"
                          style={{ color: "orange", fontSize: "20px" }}
                          onClick={handleToggle2}
                        />
                      </Nav.Link>
                      <ul
                        id="submenu2"
                        className={`sidebar-dropdown list-unstyled collapse ${
                          isExpanded2 ? "show" : ""
                        }`}
                        data-bs-parent="#sidebar"
                      >
                        <Nav.Item>
                          <Nav.Link
                            href="#training"
                            className="sidebar-link pt-0 nav-link border-bottom"
                          >
                            Training
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            href="#placements"
                            className="sidebar-link nav-link border-bottom"
                          >
                            Placements
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            href="#internship"
                            className="sidebar-link nav-link border-bottom"
                          >
                            Internship
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            href="#iit-jee"
                            className="sidebar-link nav-link border-bottom"
                          >
                            IIT - JEE
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            href="#neet"
                            className="sidebar-link nav-link border-bottom"
                          >
                            NEET
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dropdownmenu">
                          <Nav.Link
                            href="#higher-education"
                            eventKey="#higher-education"
                            className={`sidebar-link border-bottom nav-link  has-dropdown ${
                              isExpanded3 ? "expanded" : "collapsed"
                            }`}
                            data-bs-toggle="collapse"
                            data-bs-target="#submenu3"
                            aria-expanded={isExpanded3}
                            aria-controls="submenu3"
                          >
                            Higher Education
                            <RiArrowDropDownLine
                              className="drop-icon"
                              style={{ color: "orange", fontSize: "20px" }}
                              onClick={handleToggle3}
                            />
                          </Nav.Link>
                          <ul
                            id="submenu3"
                            className={`sidebar-dropdown list-unstyled collapse ${
                              isExpanded3 ? "show" : ""
                            }`}
                            data-bs-parent="#sidebar"
                          >
                            <Nav.Item>
                              <Nav.Link
                                href="#gre"
                                className="sidebar-item nav-link border-bottom"
                              >
                                GRE
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                href="#ielts"
                                className="sidebar-item nav-link border-bottom"
                              >
                                IELTS
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                href="#cat"
                                className="sidebar-item nav-link border-bottom"
                              >
                                CAT
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                href="#gate"
                                className="sidebar-item nav-link border-bottom"
                              >
                                GATE
                              </Nav.Link>
                            </Nav.Item>
                          </ul>
                        </Nav.Item>
                      </ul>
                    </Nav.Item>
                    <Nav.Link
                      href="#register-for-demo"
                      className="register d-flex ps-0"
                      eventKey="#register-for-demo"
                    >
                      <FaGift className="mt-1 me-1" />
                      <p className="fw-bold">Register For Demo</p>
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </div>
      </div>
    </>
  );
};

export default Header;

import React, { useRef } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import "./OfferLetter.css";
import Logo from "../../images/orcade.png";
import Sign from "../../images/e-sign.png";

const OfferLetter = () => {
  const offerDetails = {
    name: "Kakumani Varalakshmi",
    position: "React Developer",
    startDate: "2024-06-10",
    reportingDate: "2024-06-08",
    company: "OrcadeHub",
    location: "Remote",
    perks: "Surprise Gifts from OrcadeHub",
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Offer Letter",
  });

  return (
    <Container
      fluid
      className="offer-letter-container shadow-lg bg-light"
      style={{ width: "60rem", height: "auto", padding: "2rem" }}
    >
      <Row>
        <Col className="p-3">
          <Card ref={componentRef} className="offer-letter-content">
            <Card.Body>
              <div className="text-center">
                <img
                  src={Logo}
                  alt="orcadehub"
                  className="orcadehub-logo"
                  height={60}
                />
                <h2
                  className="mt-3"
                  style={{ color: "purple", fontWeight: "bolder" }}
                >
                  ORCADE HUB
                </h2>
                <h3 className="mt-3">Offer Letter</h3>
              </div>
              <div className="mt-4">
                <h4>Dear {offerDetails.name},</h4>
                <p>
                  We are pleased to offer you the position of{" "}
                  <strong>{offerDetails.position}</strong> at{" "}
                  <strong>{offerDetails.company}</strong>. We are confident that
                  your skills and experience will be an invaluable asset to our
                  team.
                </p>
                <p>
                  Your expected start date is{" "}
                  <strong>{offerDetails.startDate}</strong>. You will be working
                  remotely, as the position is fully remote.
                </p>
                <p>
                  Although there is no salary for this internship, you will
                  receive <strong>{offerDetails.perks}</strong>.
                </p>
                <p>
                  Your reporting date for the internship is{" "}
                  <strong>{offerDetails.reportingDate}</strong>.
                </p>
                <p>
                  We look forward to welcoming you to our team. Please let us
                  know if you have any questions or need further information.
                </p>
              </div>
              <div className="mt-4 text-right">
                <div className="d-flex justify-content-around">
                  <p>
                    <img src={Sign} alt="e-sign" width={150} />
                    <br />
                    Founder & CEO, OrcadeHub
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <p>
                  Please confirm your acceptance of this offer by signing below
                  and returning this letter by <strong>2023-07-01</strong>. We
                  are excited to have you on board!
                </p>
                <p>Sincerely,</p>
                <p>OrcadeHub Team</p>
              </div>
              <div className="mt-5">
                <h5>Intern's Acceptance:</h5>
                <p>Signature: __________________________</p>
                <p>Date: __________________________</p>
              </div>
            </Card.Body>
          </Card>
          <div className="text-center mt-4">
            <Button variant="primary" onClick={handlePrint}>
              Print Offer Letter
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OfferLetter;

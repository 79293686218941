import React, { useState } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // Initialize the navigate function

  const sendLogin = async (event) => {
    navigate("/admin/home");
    event.preventDefault();
    try {
      
      const response = await axios.post("http://localhost:3300/contact", {
        email: email,
        password: password,
      });
      console.log(response);
      if (response.status === 200) {
        navigate("/admin/home"); // Navigate to /admin/home on successful response
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="loginForm p-3 shadow-lg my-5"
      style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "480px" }}
    >
      <Form onSubmit={sendLogin}>
        <h3 className="text-center">LOGIN</h3>

        <Form.Group className="row my-4" controlId="formEmail">
          <Form.Control
            name="email"
            type="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            className="bg-light border border-dark"
            style={{ height: "3rem" }}
          />
        </Form.Group>

        <Form.Group className="row my-4" controlId="formPassword">
          <Form.Control
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="bg-light border border-dark"
            style={{ height: "3rem" }}
          />
        </Form.Group>

        <span className="text-center">
          <button
            className="border-0 rounded px-3 py-1 my-4"
            style={{
              color: "orange",
              backgroundColor: "black",
              width: "100%",
              height: "3rem",
            }}
            type="submit"
          >
            Login
          </button>
        </span>
      </Form>
    </div>
  );
};

export default Login;

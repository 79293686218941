import Testimonials1 from "../images/profile.jpg";
import Testimonials2 from "../images/profile.jpg";
import Testimonials3 from "../images/profile.jpg";
import Testimonials4 from "../images/profile.jpg";
import Testimonials5 from "../images/profile.jpg";

export const testimonials = [
  {
    src: Testimonials1,
    college: "Sri Mittapalli College of Engineering",
    place: "Guntur",
    comment: "We organized a training program on MERN Stack development, and the feedback from our students has been overwhelmingly positive. The practical sessions were particularly beneficial."
  },
  {
    src: Testimonials2,
    college: "Chalapathi Institute of Engineering & Technology",
    place: "Guntur",
    comment: "The training on Blockchain and Web Development was outstanding. Our students gained valuable insights and hands-on experience that will be very useful in their careers."
  },
  {
    src: Testimonials3,
    college: "Mallareddy University",
    place: "Hyderabad",
    comment: "The Java Full Stack and C with Data Structures training provided by your team was exceptional. The curriculum was well-structured and the instructors were highly knowledgeable."
  },
  {
    src: Testimonials4,
    college: "KKR & KSR Institute of Technologies",
    place: "Guntur",
    comment: "We had a great experience with the Full Stack Web Development and Python Programming training. The interactive sessions and real-world examples helped our students grasp complex concepts easily."
  },
  {
    src: Testimonials5,
    college: "Mallareddy Engineering College for Women",
    place: "Hyderabad",
    comment: "The C and Python problem-solving training on HackerRank was very effective. Our students appreciated the detailed explanations and the practical approach to solving problems."
  }
];

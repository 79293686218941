import React from "react";
import { data } from "./Data-services";
import "./Services.css"; // Ensure you have the CSS file for custom styling

const Services = () => {
  return (
    <>
      <h1 className="d-flex justify-content-center my-3" id="services">
        Services We Offer
      </h1>
      <div className="row mx-2 ser" style={{ height: "auto" }}>
        {data.map((service) => (
          <div
            className="col-lg-3 col-md-4 col-sm-6 col-xs-12 pt-4 pb-4"
            key={service.id}
          >
            <div
              className="card service-card shadow-lg bg-secondary"
              style={{ width: "100%", height: "100%" }}
            >
              <img
                src={service.coverimg}
                className="card-img-top"
                alt={service.title}
                style={{ height: "15rem" }}
              />
              <div className="card-body">
                <h5 className="card-title">{service.title}</h5>
                <p className="card-text">{service.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Services;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';

const departments = ["CSE", "EEE", "ECE", "ME", "CE", "IT"];

const ClgEnroll = () => {
  const [name, setName] = useState('');
  const [rollNumber, setRollNumber] = useState('');
  const [email, setEmail] = useState('');
  const [college, setCollege] = useState('');
  const [collegeList, setCollegeList] = useState([]);
  const [department, setDepartment] = useState(departments[0]);

  useEffect(() => {
    // Fetch the list of colleges from the backend
    const fetchColleges = async () => {
      try {
        const response = await axios.get('http://localhost:3300/colleges');
        setCollegeList(response.data);
        if (response.data.length > 0) {
          setCollege(response.data[0].id);
        }
      } catch (error) {
        console.error('Error fetching colleges:', error);
      }
    };
    fetchColleges();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      name,
      rollNumber,
      email,
      college,
      department,
    };

    try {
      const response = await axios.post('http://localhost:3300/enroll-student', formData);
      console.log(response.data);
    } catch (error) {
      console.error('Error enrolling student:', error);
    }
  };

  return (
    <div
      className="form-container p-3 shadow-lg my-5"
      style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '480px' }}
    >
      <Form onSubmit={handleSubmit}>
        <h3 className="text-center">Certificate Enrollment</h3>

        <Form.Group className="my-4" controlId="formName">
          <Form.Control
            type="text"
            value={name}
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
            className="bg-light border border-dark"
            style={{ height: '3rem' }}
          />
        </Form.Group>

        <Form.Group className="my-4" controlId="formRollNumber">
          <Form.Control
            type="text"
            value={rollNumber}
            placeholder="Roll Number"
            onChange={(e) => setRollNumber(e.target.value)}
            className="bg-light border border-dark"
            style={{ height: '3rem' }}
          />
        </Form.Group>

        <Form.Group className="my-4" controlId="formEmail">
          <Form.Control
            type="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            className="bg-light border border-dark"
            style={{ height: '3rem' }}
          />
        </Form.Group>

        <Form.Group className="my-4" controlId="formCollege">
          <Form.Control
            as="select"
            value={college}
            onChange={(e) => setCollege(e.target.value)}
            className="bg-light border border-dark"
            style={{ height: '3rem' }}
          >
            {collegeList.map((clg) => (
              <option key={clg.id} value={clg.id}>
                {clg.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="my-4" controlId="formDepartment">
          <Form.Control
            as="select"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            className="bg-light border border-dark"
            style={{ height: '3rem' }}
          >
            {departments.map((dept, index) => (
              <option key={index} value={dept}>
                {dept}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Button
          variant="dark"
          type="submit"
          style={{ width: '100%', height: '3rem', backgroundColor: 'black', color: 'orange' }}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default ClgEnroll;

import Carousel from "react-bootstrap/Carousel";
// import c1 from "../images/c1.jpg"; // Ensure the path to the image is correct
import c0 from "../images/gif.gif"; // Add other images as needed
// import c2 from "../images/i3.jpg";

function Carosel() {
  return (
    <Carousel >
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={c0}
          alt="First slide"
          height={700}
        />
        <Carousel.Caption>
          {/* <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>

      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src={c1}
          alt="Second slide"
          height={600}
        />
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={c2}
          alt="Third slide"
          height={600}
        />
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
  );
}

export default Carosel;

import React from 'react'
import Carosel from '../components/Carosel'
import Courses from '../components/Courses'
import Services from '../components/Services'
import Testimonials from '../components/Testimonials'
// import Certificate from './CertificatesPage'
import Contact from '../components/Contact'
// import Enroll from '../components/Enroll'
const Home = () => {
  return (
    <>
      <Carosel/>
      <Services/>
      <Courses/>
      {/* <Enroll/> */}
      <Contact/>
      <Testimonials/>
      {/* <Certificate/> */}
    </>
  )
}

export default Home
import React from "react";
import B1 from "../images/bro1.jpg";
import B2 from "../images/bro2.jpg";
import B3 from "../images/bro3.jpg";
import B4 from "../images/bro4.jpg";
const Broucher = () => {
  return (
    <>
      <h1 className="text-center">Brochure</h1>
      <div className="text-center bg-dark">
        <img src={B1} alt="bro" style={{width:'99vw'}}/>
        <img src={B2} alt="bro" style={{width:'99vw'}}/>
        <img src={B3} alt="bro" style={{width:'99vw'}}/>
        <img src={B4} alt="bro" style={{width:'99vw'}}/>
      </div>
    </>
  );
};

export default Broucher;

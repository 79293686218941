import React, { useState } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = async (event) => {
    event.preventDefault();
    try {
        alert("submitted");
      const response = await axios.post("http://localhost:8000/contact", {
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        message: message
      });
      console.log("connected", response.data)
    } catch(error) {
        console.log(error);
    }
  };

  return (
    <div
      className="contactForm p-3 shadow-lg my-5"
      style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "480px" }}
      id="contact"
    >
      <Form className="row">
        <p>GET IN TOUCH</p>
        <h3>Fill The Form Below</h3>
        <Form.Group className="my-3" controlId="formName">
          <Form.Control
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="bg-light"
          />
        </Form.Group>
      </Form>
      <Form className="row">
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Control
            name="email"
            type="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            className="bg-light"
          />
        </Form.Group>
      </Form>
      <Form className="row">
        <Form.Group className="mb-3" controlId="formPhoneNo">
          <Form.Control
            type="tel"
            name="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Phone Number"
            className="bg-light"
          />
        </Form.Group>
      </Form>
      <Form className="row">
        <Form.Group className="mb-3" controlId="formDescription">
          <Form.Control
            as="textarea"
            rows="3"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            type="text"
            placeholder="Your message here"
            className="bg-light"
          />
        </Form.Group>
        <span className="text-center">
          <button
            className="border-0 rounded px-3 py-1"
            style={{ color: "orange", backgroundColor: "black",fontSize:'20px',fontWeight:'bold' }}
            type="submit"  onClick={(event) => sendEmail(event)}
          >
            Submit
          </button>
        </span>
      </Form>
    </div>
  );
};

export default Contact;

import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./Courses.css"; // Ensure you have the CSS file for custom styling

function Courses() {
  return (
    <Accordion defaultActiveKey="0" id="courses">
      <h2 className="text-center my-2" style={{fontSize:'40px',fontWeight:'bolder'}}>Courses Offering</h2>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Emerging Technologies Training</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li>🌟 MERN Stack Development</li>
            <li>🌟 Artificial Intelligence & Machine Learning</li>
            <li>🌟 DevOps AWS</li>
            <li>🌟 Full Stack Development with Java</li>
            <li>🌟 Full Stack Development with Python</li>
            <li>🌟 Data Science / Data Analytics</li>
            <li>🌟 Internet of Things (IOT)</li>
            <li>🌟 Cyber Security</li>
            <li>🌟 Block Chain Technology</li>
            <li>🌟 Cloud Computing</li>
            <li>🌟 Java & Advanced Java Programming</li>
            <li>🌟 Python & Advanced Python Programming</li>
            <li>🌟 Web Technologies</li>
            <li>🌟 MS Azure</li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Technical Training</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li>⚙️ C, C++ Programming</li>
            <li>⚙️ DS & Algorithms</li>
            <li>⚙️ Coding Skills</li>
            <li>⚙️ Database Concepts & SQL</li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Non-Technical Training</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li>✏️ Aptitude</li>
            <li>✏️ Reasoning</li>
            <li>✏️ Verbal</li>
            <li>✏️ Soft Skills</li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Smart Technical Projects</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li>🔧 Mini Projects</li>
            <li>🔧 Major Projects</li>
            <li>🔧 Innovative Initiatives</li>
            <li>🔧 Flagship Endeavours</li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>ERP (Enterprise Resource Planning)</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li>📊 Accreditation Management</li>
            <p>Ranking for NAAC, NBA, ABET, NIRF & QS</p>
            <li>📊 Administration Management</li>
            <p>Admission, Infrastructure, Staff Management</p>
            <li>📊 Campus Management</li>
            <p>College Academics & Operations Management</p>
            <li>📊 Department Management</li>
            <p>Dept. Level Faculty, Student & Infrastructure Management</p>
            <li>📊 Learning Management</li>
            <p>Classes, Study Material, Exam Management</p>
            <li>📊 Parent Management</li>
            <p>Student Life Cycle & Interaction Management</p>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>GATE</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li>🎓 Coaching for GATE (Online/Offline)</li>
            <p>Branches: CSE, ECE, EEE</p>
            <p>Any Technical Competitive Examinations</p>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>ECET</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li>🎓 Coaching for ECET (Online/Offline)</li>
            <p>Branches: ECE, EEE, EIE, ME, Civil, CSE, IT</p>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Courses;

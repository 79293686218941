import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Verified from "./pages/Verified";
import Download from "./components/certificate/Download";
import OfferLetter from "./components/certificate/OfferLetter";
import CertificatesPage from "./pages/CertificatesPage";
import Login from "./components/Login";
import AdminsHome from "./pages/AdminsHome";
import ClgEnroll from "./pages/ClgEnroll";
import Brochure from "./components/Brochure";
const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/test" element={<ClgEnroll />}></Route>
          <Route exact path="/admin/home" element={<AdminsHome />}></Route>
          <Route exact="/certificates" element={<CertificatesPage />}></Route>
          <Route exact path="/login" element={<Login />}></Route>
          <Route exact path="/admin" element={<CertificatesPage />}></Route>
          <Route exact path="/verify" element={<Verified />}></Route>
          <Route exact path="/download" element={<Download />}></Route>
          <Route exact path="/brochure" element={<Brochure />}></Route>
          <Route
            exact
            path="/generate-offer-letter"
            element={<OfferLetter />}
          ></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;

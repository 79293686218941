import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./AdminsHome.css";

const certificates = [
  { name: "Course Completion Certificate", path: "/" },
  { name: "College Certificate", path: "/" },
  { name: "Training Certificate", path: "/training" },
  {
    name: "Internship Certificate", path: "/",
  },
];

const offerLetters = [
  { name: "Job Offer Letter", path: "/" },
  { name: "Internship Offer Letter", path: "/" },
];

const AdminsHome = ({ children }) => {
  return (
    <div>
    
      <div className="content" >
        <div className="sidebar">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Certificates</Accordion.Header>
              <Accordion.Body>
                {certificates.map((certificate, index) => (
                  <div key={index} className="oper">
                    <Link to={certificate.path} activeClassname="active" className="oper_items">
                      {certificate.name}
                    </Link>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Offer Letters</Accordion.Header>
              <Accordion.Body>
                {offerLetters.map((offerLetter, index) => (
                  <div key={index} className="oper">
                    <Link to={offerLetter.path} className="oper_items">
                      {offerLetter.name}
                    </Link>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
          </div>
          <main>{ children }</main>
    </div>
  );
};

export default AdminsHome;
import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Certi from "./Certi";
import config from "../../config"; // Ensure you import the config file
import Courses from "../Courses";
import Services from "../Services";
import Testimonials from "../Testimonials";
import Contact from "../Contact";

const Download = () => {
  const location = useLocation();
  const { email } = location.state;
  const [certificateDetails, setCertificateDetails] = useState(null);
  const [error, setError] = useState("");

  const certiRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => certiRef.current,
    documentTitle: "Certificate",
  });

  const baseURL =
    process.env.NODE_ENV === "development"
      ? config.LOCAL_BASE_URL
      : config.BASE_URL;

  console.log(baseURL);

  useEffect(() => {
    const fetchCertificateDetails = async () => {
      try {
        const response = await axios.get(
          `${baseURL}download/${email}`
        );
        if (response.data.data) {
          setCertificateDetails(response.data.data);
        } else {
          setError("Certificate not found or hasn't been released yet.");
        }
      } catch (err) {
        setError("An error occurred while fetching certificate details.");
      }
    };

    fetchCertificateDetails();
  }, [email, baseURL]);

  return (
    <>
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <Row>
          <Col>
            {certificateDetails ? (
              <>
                <div ref={certiRef}>
                  <Certi certificateDetails={certificateDetails} />
                </div>
                <Button
                  onClick={handlePrint}
                  variant="primary"
                  className="mt-3"
                >
                  Click here to Download
                </Button>
              </>
            ) : (
              <>
                <Alert variant="danger" className="text-center">
                  {error}
                  <br />
                  <Link to="/">Go back to homepage to re-enter Email</Link>
                </Alert>
                <Alert variant="info" className="text-center">
                  Complete course to get certified
                  <br />
                  <Link to="/">Go to Course</Link>
                </Alert>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Services />
      <Courses />
      <Contact />
      <Testimonials />
    </>
  );
};

export default Download;

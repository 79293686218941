import React from "react";
import { Container, Row, Col, Card, ListGroup, Badge } from "react-bootstrap";
import Logo from "../../images/log.png";
import Sign from "../../images/e-sign.png";

const Certi = ({ certificateDetails }) => {
  return (
    <Container
      fluid
      className="certificate-container shadow-lg bg-dark-subtle"
      style={{ width: "60rem", height: "43rem" }}
    >
      <Row>
        {/* Key Skills Section */}
        <Col md={3} className="key-skills-section p-4 rounded-5">
          <Badge pill variant="warning" className="mb-3">
            SPECIALIZED TRAININGS
          </Badge>
          <h3>Key Skills</h3>
          <ListGroup variant="flush" className="mt-3">
            {certificateDetails.skills.map((skill, index) => (
              <ListGroup.Item
                key={index}
                className="bg-primary text-white rounded-5"
              >
                {skill}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>

        {/* Certificate Content Section */}
        <Col md={9} className="p-3">
          <Card className="certificate-content">
            <Card.Body>
              <div className="text-center">
                <span>
                  {" "}
                  <img
                    src={Logo}
                    alt="orcadehub"
                    className="orcadehub-logo"
                    height={60}
                  />{" "}
                  <span
                    style={{
                      color: "#4b0082",
                      fontWeight: "bolder",
                      fontSize: "30px",
                    }}
                  >
                    ORCADE HUB
                  </span>
                </span>
                <h3 className="mt-3">Course Certificate</h3>
              </div>
              <div className="mt-4">
                <h4 className="text-center">{certificateDetails.name}</h4>
                <p className="text-center">
                  has successfully completed the {certificateDetails.duration}{" "}
                  course and training in
                </p>
                <h3 className="text-center">{certificateDetails.course}</h3>
                <p className="text-center">
                  with an aggregate score of {certificateDetails.score}%.
                </p>
                <p>
                  This certificate is awarded in recognition of the successful
                  completion of an intensive and comprehensive learning program.
                  The recipient has demonstrated exceptional dedication,
                  discipline, and intellectual rigor throughout their studies.
                  Their commitment to excellence and perseverance in mastering
                  the subject matter have equipped them with the necessary
                  skills and knowledge to excel in their future endeavors.
                </p>
              </div>
              <div className="mt-4 text-right">
                <img
                  src={Sign}
                  alt="Signature"
                  className="signature ms-5"
                  height={50}
                />
                <div className="d-flex justify-content-around">
                  <p>
                    Gurram Ajith Kumar
                    <br />
                    Founder & CEO, OrcadeHub
                  </p>
                  <p>
                    Issued on: {certificateDetails.issuedDate} <br />
                    Certificate no.: {certificateDetails.certificateId}
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <p>
                  For certificate authentication, please visit{" "}
                  <a href="/" target="_blank" rel="noopener noreferrer">
                    Click here to verify
                  </a>
                </p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Certi;

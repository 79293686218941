import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import Courses from "../components/Courses";
import Services from "../components/Services";
import Testimonials from "../components/Testimonials";
import Contact from "../components/Contact";
import OfferLetter from "../components/certificate/OfferLetter";

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [certificateNumber, setCertificateNumber] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal1 = () => setShowModal1(true);
  const handleCloseModal1 = () => setShowModal1(false);

  const handleInputChange = (event) => {
    setCertificateNumber(event.target.value);
  };

  const handleInputChange1 = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/verify", { state: { certificateNumber } });
    handleCloseModal();
  };

  const handleSubmit1 = (event) => {
    event.preventDefault();
    navigate("/download", { state: { email } });
    handleCloseModal1();
  };

  return (
    <>
      <h1 className="text-center my-2 mb-5">
        Verify/Download your certificates here...
      </h1>
      <div className="d-flex justify-content-around my-3" id="certificates">
        <Button className="btn btn-success" onClick={handleShowModal}>
          Verify Certificate
        </Button>
        <Button className="btn btn-success" onClick={handleShowModal1}>
          Download Certificate
        </Button>
      </div>
      <OfferLetter/>
      <Services />
      <Courses />
      <Contact />
      <Testimonials />
      {/* Verify Certificate Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Verify Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formCertificateNumber">
              <Form.Label>Certificate Number</Form.Label>
              <Form.Control
                type="text"
                value={certificateNumber}
                onChange={handleInputChange}
                placeholder="Enter certificate number"
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Download Certificate Modal */}
      <Modal show={showModal1} onHide={handleCloseModal1}>
        <Modal.Header closeButton>
          <Modal.Title>Download Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit1}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={handleInputChange1}
                placeholder="Enter email"
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Home;

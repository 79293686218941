import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Container, Row, Col, Alert } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import config from "../config";
import Courses from "../components/Courses";
import Services from "../components/Services";
import Testimonials from "../components/Testimonials";
import Contact from "../components/Contact";

const Verified = () => {
  const location = useLocation();
  const { certificateNumber } = location.state;
  const [certificateDetails, setCertificateDetails] = useState(null);
  const [error, setError] = useState("");

  const baseURL =
    process.env.NODE_ENV === "development"
      ? config.LOCAL_BASE_URL
      : config.BASE_URL;

  console.log(baseURL);

  useEffect(() => {
    const fetchCertificateDetails = async () => {
      try {
        const response = await axios.get(`${baseURL}verify/${certificateNumber}`);
        if (response.data.valid) {
          setCertificateDetails(response.data.data);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError("An error occurred while fetching certificate details.");
      }
    };

    fetchCertificateDetails();
  }, [certificateNumber, baseURL]);

  return (
    <>
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <Row>
          <Col>
            {certificateDetails ? (
              <Card className="shadow-lg" style={{ width: "24rem" }}>
                <Card.Body>
                  <Card.Title className="text-center mb-4">
                    Certificate Details
                  </Card.Title>
                  <Card.Text>
                    <strong>Name:</strong> {certificateDetails.name}
                    <br />
                    <strong>Course:</strong> {certificateDetails.course}
                    <br />
                    <strong>Enrolled Date:</strong>{" "}
                    {new Date(certificateDetails.enrolledDate).toLocaleDateString()}
                    <br />
                    <strong>Duration:</strong> {certificateDetails.duration}
                    <br />
                    <strong>Certificate ID:</strong>{" "}
                    {certificateDetails.certificateId}
                    <br />
                    <strong>Status:</strong> {certificateDetails.status}
                  </Card.Text>
                </Card.Body>
              </Card>
            ) : (
              <Alert variant="danger" className="text-center">
                {error}
                <br />
                <Link to="/">
                  Go back to homepage to re-enter certificate number
                </Link>
              </Alert>
            )}
          </Col>
        </Row>
      </Container>

      <Services />
      <Courses />
      <Contact />
      <Testimonials />
    </>
  );
};

export default Verified;

// src/Data.js
import S1 from "../images/s1.jpg";
import S2 from "../images/s2.jpg";
import S3 from "../images/s3.jpg";
import S4 from "../images/s4.jpg";
import S5 from "../images/s5.jpg";
import S6 from "../images/s6.jpg";
import S7 from "../images/s7.jpg";
import S8 from "../images/s8.jpg";

export const data = [
  {
    id: 1,
    title: "ERP Solutions",
    coverimg: S2,
    description:
      "Our ERP Solutions provide comprehensive management tools for all your business processes. From inventory and order management to accounting and HR, our ERP systems integrate all facets of your operations to streamline workflows and improve efficiency."
  },
  {
    id: 2,
    title: "Web Development",
    coverimg: S3,
    description:
      "We offer top-notch web development services to create robust, scalable, and visually appealing websites. Our team is skilled in using the latest technologies like HTML, CSS, JavaScript, and frameworks such as React and Angular to build dynamic and responsive web applications."
  },
  {
    id: 3,
    title: "Mobile App Services",
    coverimg: S7,
    description:
      "Our mobile app services cover the entire spectrum of app development, from concept to deployment. We develop high-performance apps for both iOS and Android platforms, ensuring a seamless user experience and optimal performance across devices."
  },
  {
    id: 4,
    title: "Workshops",
    coverimg: S5,
    description:
      "We conduct hands-on workshops that focus on the latest industry trends and technologies. These workshops are designed to provide practical knowledge and skills that participants can immediately apply to their work or projects."
  },
  {
    id: 5,
    title: "Virtual Internships",
    coverimg: S4,
    description:
      "Our virtual internships offer students and young professionals the opportunity to gain real-world experience from the comfort of their homes. Participants work on actual projects, receive mentorship, and build their resumes with meaningful experiences."
  },
  {
    id: 6,
    title: "Online Courses",
    coverimg: S6,
    description:
      "We provide a wide range of online courses covering various subjects and skill levels. Our courses are designed by industry experts and delivered through an intuitive platform, allowing learners to study at their own pace and convenience."
  },
  {
    id: 7,
    title: "Digital Marketing",
    coverimg: S1,
    description:
      "Our digital marketing services help businesses reach their target audience through effective online strategies. We specialize in SEO, social media marketing, email marketing, and PPC advertising to drive traffic, increase engagement, and boost conversions."
  },
  {
    id: 8,
    title: "Provide Trainers",
    coverimg: S8,
    description:
      "We offer professional trainers who are experts in their respective fields. Whether you need training for technical skills, soft skills, or industry-specific knowledge, our trainers deliver customized programs to meet your organization's needs."
  },
];
